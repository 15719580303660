export const config = {
    RPC_URL: 'https://public-celestia-rpc.numia.xyz',
    REST_URL: 'https://public-celestia-lcd.numia.xyz',
    EXPLORER_URL: 'https://www.mintscan.io/celestia',
    STAKING_URL: 'https://celestia.omniflix.co/stake',
    WALLET_URL: 'https://celestia.omniflix.co',
    COIN_IMAGE_URL: 'https://raw.githubusercontent.com/cosmos/chain-registry/master/celestia/images/celestia.png',
    NETWORK_NAME: 'Celestia',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'celestia',
    CHAIN_NAME: 'celestia',
    COIN_DENOM: 'TIA',
    COIN_MINIMAL_DENOM: 'utia',
    COIN_DECIMALS: 6,
    PREFIX: 'celestia',
    COIN_TYPE: 118,
    COSMOSTAION: 'celestia',
    COINGECKO_ID: 'celestia',
    GAS_PRICE_STEP_LOW: 0.01,
    GAS_PRICE_STEP_AVERAGE: 0.02,
    GAS_PRICE_STEP_HIGH: 0.1,
    // FEATURES: ['cosmwasm', 'ibc-transfer', 'ibc-go'],
};
